let upperConstLabels = [
	{
		label: 'C3M2',
		gravity: 'center',
		channelId: 1,
		value: 0,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '15px Arial'
	}, {
		label: 'C4M1',
		gravity: 'center',
		channelId: 2,
		value: 0,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '15px Arial'
	}, {
		label: 'F3M2',
		gravity: 'center',
		channelId: 3,
		value: 0,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '15px Arial'
	}, {
		label: '-37.5 ',
		gravity: 'right',
		channelId: 3,
		value: 37.5,
		xOffset: -1,
		yOffset: 0,
		color: 'red',
		font: '9px Arial'
	}, {
		label: '37.5 ',
		gravity: 'right',
		channelId: 3,
		value: -37.5,
		xOffset: -1,
		yOffset: 0,
		color: 'red',
		font: '9px Arial'
	}, {
		label: 'F4M1',
		gravity: 'center',
		channelId: 4,
		value: 0,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '15px Arial'
	}, {
		label: '-37.5 ',
		gravity: 'right',
		channelId: 4,
		value: 37.5,
		xOffset: -1,
		yOffset: 0,
		color: 'red',
		font: '9px Arial'
	}, {
		label: '37.5 ',
		gravity: 'right',
		channelId: 4,
		value: -37.5,
		xOffset: -1,
		yOffset: 0,
		color: 'red',
		font: '9px Arial'
	}, {
		label: 'O1M2',
		gravity: 'center',
		channelId: 5,
		value: 0,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '15px Arial'
	}, {
		label: 'O2M1',
		gravity: 'center',
		channelId: 6,
		value: 0,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '15px Arial'
	}, {
		label: 'E1M2',
		gravity: 'center',
		channelId: 7,
		value: 0,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '15px Arial'
	}, {
		label: 'E2M1',
		gravity: 'center',
		channelId: 8,
		value: 0,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '15px Arial'
	}, {
		label: 'EMGR',
		gravity: 'center',
		channelId: 9,
		value: 0,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '15px Arial'
	}, {
		label: 'ECG',
		gravity: 'center',
		channelId: 10,
		value: 0,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '15px Arial'
	}
]

let lowerConstLabels = [
	{
		label: 'Sound',
		gravity: 'center',
		channelId: 11,
		value: 0,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '15px Arial'
	}, {
		label: 'NPress',
		gravity: 'center',
		channelId: 12,
		value: 0,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '15px Arial'
	}, {
		label: 'Therm',
		gravity: 'center',
		channelId: 13,
		value: 0,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '15px Arial'
	}, {
		label: 'Thor',
		gravity: 'center',
		channelId: 14,
		value: 0,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '15px Arial'
	}, {
		label: 'Abdo',
		gravity: 'center',
		channelId: 15,
		value: 0,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '15px Arial'
	}, {
		label: 'SpO2',
		gravity: 'center',
		channelId: 16,
		value: 75,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '15px Arial'
	// }, {
		// label: '100',
		// gravity: 'right',
		// channelId: 16,
		// value: 100,
		// xOffset: -3,
		// yOffset: 0,
		// color: 'black',
		// font: '8px Arial'
	// }, {
	// 	label: '50',
	// 	gravity: 'right',
	// 	channelId: 16,
	// 	value: 100,
	// 	xOffset: -3,
	// 	yOffset: 23,
	// 	color: 'black',
	// 	font: '8px Arial'
	}, {
		label: 'LimbL',
		gravity: 'center',
		channelId: 17,
		value: 0,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '15px Arial'
	}, {
		label: 'LimbR',
		gravity: 'center',
		channelId: 18,
		value: 0,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '15px Arial'
	}, {
		label: 'Pulse',
		gravity: 'center',
		channelId: 19,
		value: 45,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '15px Arial'
	// }, {
	// 	label: '90',
	// 	gravity: 'right',
	// 	channelId: 19,
	// 	value: 90,
	// 	xOffset: -3,
	// 	yOffset: 0,
	// 	color: 'black',
	// 	font: '8px Arial'
	// }, {
	// 	label: '0',
	// 	gravity: 'right',
	// 	channelId: 19,
	// 	value: 0,
	// 	xOffset: -3,
	// 	yOffset: -2,
	// 	color: 'black',
	// 	font: '8px Arial'
	}, {
		label: 'Position',
		gravity: 'center',
		channelId: 20,
		value: 0,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '15px Arial'
	// }, {
	// 	label: 'R',
	// 	gravity: 'right',
	// 	channelId: 20,
	// 	value: 2,
	// 	xOffset: -3,
	// 	yOffset: 0,
	// 	color: 'black',
	// 	font: '8px Arial'
	// }, {
	// 	label: 'S',
	// 	gravity: 'right',
	// 	channelId: 20,
	// 	value: 1,
	// 	xOffset: -3,
	// 	yOffset: 0,
	// 	color: 'black',
	// 	font: '8px Arial'
	// }, {
	// 	label: 'L',
	// 	gravity: 'right',
	// 	channelId: 20,
	// 	value: 0,
	// 	xOffset: -3,
	// 	yOffset: 0,
	// 	color: 'black',
	// 	font: '8px Arial'
	// }, {
	// 	label: 'P',
	// 	gravity: 'right',
	// 	channelId: 20,
	// 	value: -1,
	// 	xOffset: -3,
	// 	yOffset: 0,
	// 	color: 'black',
	// 	font: '8px Arial'
	// }, {
	// 	label: 'U',
	// 	gravity: 'right',
	// 	channelId: 20,
	// 	value: -2,
	// 	xOffset: -3,
	// 	yOffset: 0,
	// 	color: 'black',
	// 	font: '8px Arial'
	}
]

export {
	upperConstLabels,
	lowerConstLabels
}