import React, { Component } from 'react'
import axios from 'axios'
import Dropdown from 'react-bootstrap/Dropdown'
import DrawGraph from './DrawGraph'
import EventDataCon from './EventDataCon'


class ReportDropdown extends Component {
	constructor(props) {
		super(props)
		this.state = {
			start01: false,
			wait01: false,
			wait02: false,
			gragh_res: false,
			visible: "none",
			graphData: {},
			eventData: [],
		}
	}
	
	CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<a
			href="/#/"
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}>
			{children}
			&#x25bc;
		</a>
	))

	saveGraph = async (graphData) => {
		let result = await axios.post('/graph', {graphData: graphData, account:this.props.account ,time:(window.location.href[window.location.href.length-1])});
		//console.log(graphData);
		this.setState({gragh_res: result.data.success})
	}
	savedata = (list) =>{
		this.setState({
			eventData: list
		})
	}
	oknext01 = (bool) =>{
		this.setState({
			wait01: bool
		})
	}
	oknext02 = (bool) =>{
		this.setState({
			wait02: bool
		})
	}

	render () {
		return(
			<>
				{this.state.start01?<DrawGraph
					stage={this.props.stage}
					visible={this.state.visible}
					saveGraph={this.saveGraph}
					all_event={this.props.all_event}
					arousal_s= {this.props.arousal_s}
					spo2_s= {this.props.spo2_s}
					Mixed_Apnea_s={this.props.Mixed_Apnea_s}
					Obstructive_Apnea_s={this.props.Obstructive_Apnea_s}
					Central_Apnea_s={this.props.Central_Apnea_s}
					Hypopnea_s={this.props.Hypopnea_s}
					limb_r_s={this.props.limb_r_s}
					modeArr={this.props.modeArr}
					msevent={this.props.msevent}
					wait01={this.state.wait01}
					oknext01={this.oknext01}
					gragh_res={this.gragh_res} 
				/>:<></>}
				{this.state.start01?<EventDataCon
					stage={this.props.stage}
					eventData={this.state.eventData}
					savedata={this.savedata}
					all_event={this.props.all_event}
					arousal_s= {this.props.arousal_s}
					spo2_s= {this.props.spo2_s}
					Mixed_Apnea_s={this.props.Mixed_Apnea_s}
					Obstructive_Apnea_s={this.props.Obstructive_Apnea_s}
					Central_Apnea_s={this.props.Central_Apnea_s}
					Hypopnea_s={this.props.Hypopnea_s}
					limb_r_s={this.props.limb_r_s}
					msevent={this.props.msevent}
					spo2_deast={this.props.spo2_deast}
					spo2_respiratory={this.props.spo2_respiratory}
					modeArr={this.props.modeArr}
					wait02={this.state.wait02}
					oknext02={this.oknext02}
				/>:<></>}
				<Dropdown onSelect={ (item) => {
					if (item === 'workshop') {
						this.setState({start01: true})
						// 繪製圖片傳至後端 (不知道會不會出現canvas還沒畫完的情形?)
						this.setState({visible: "none"}); // 方便繪製的工具，完成後須註解
						const loop=()=>{
						if(this.state.wait01===true &&this.state.wait02===true && this.state.gragh_res===true){					
							let xhttp = new XMLHttpRequest();// 產生報告並下載
							xhttp.responseType = 'blob';
							xhttp.onload = () => {
								let blob = xhttp.response; //Blob資料
								if (xhttp.status === 200) {
									if (blob && blob.size > 0) {
										let element = document.createElement('a');
										element.setAttribute('href', URL.createObjectURL(blob));
										element.setAttribute('download',  String(this.props.account)+"_第"+String((window.location.href[window.location.href.length-1]))+'次.docx');
										document.body.appendChild(element);
										element.click();
									} 
								} 
							}
							let data = {account: this.props.account, psgfileid: this.props.psgFileId,ev_data: this.state.eventData,time:(window.location.href[window.location.href.length-1])};
							let url = "https://scoring.ncbci.org/word";
							xhttp.open("POST", url, true);
							xhttp.setRequestHeader("Content-type", "application/json");
  							xhttp.send(JSON.stringify(data));
							//this.setState({start01: false})
						}else{
							window.setTimeout(loop, 100); 
							}
						}
						window.setTimeout(loop, 100); 
						// alert("一致性結果報告修復中，修復完畢會再寄信通知大家，造成您的不便十分抱歉!");
						// alert("此功能將於 7/26 以前開放!");
					}
					else if (item === 'sleep_report') {
						alert("尚未開放的功能喔~\n敬請期待!");
					}}}>
					<Dropdown.Toggle as={this.CustomToggle} id="account-dropdown">
						產生報告&nbsp;
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item eventKey={'workshop'} style={{color: 'black'}}>
							工作坊報告
						</Dropdown.Item>
						<Dropdown.Item eventKey={'sleep_report'} style={{color: 'black'}}>
							睡眠檢查報告
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</>
		)
	}
}

export default ReportDropdown