let linesInHypnogram = {
	horizontalLines: [
		{
			channelId: 0,
			value: 3,
			dashed: false,
			color: '#c0c0c0',
		},
		{
			channelId: 0,
			value: 2,
			dashed: false,
			color: '#c0c0c0',
		},
		{
			channelId: 0,
			value: 1,
			dashed: false,
			color: '#c0c0c0',
		},
		{
			channelId: 0,
			value: 0,
			dashed: false,
			color: '#c0c0c0',
		},
		{
			channelId: 0,
			value: -1,
			dashed: false,
			color: '#c0c0c0',
		},
		// {
		// 	channelId: 0,
		// 	value: -2,
		// 	dashed: false,
		// 	color: '#c0c0c0',
		// },
	],
	verticalLines: []
}

export default linesInHypnogram