let labelsForHypnogram = [
	// {
	// 	label: '?',
	// 	gravity: 'center',
	// 	channelId: 0,
	// 	value: -2,
	// 	xOffset: 0,
	// 	yOffset: 0,
	// 	color: 'black',
	// 	font: '20px'
	// },
		{
		label: 'R',
		gravity: 'center',
		channelId: 0,
		value: -1,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '20px'
	},
		{
		label: 'W',
		gravity: 'center',
		channelId: 0,
		value: 0,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '20px'
	},
		{
		label: 'N1',
		gravity: 'center',
		channelId: 0,
		value: 1,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '20px'
	},
		{
		label: 'N2',
		gravity: 'center',
		channelId: 0,
		value: 2,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '20px'
	},
		{
		label: 'N3',
		gravity: 'center',
		channelId: 0,
		value: 3,
		xOffset: 0,
		yOffset: 0,
		color: 'black',
		font: '20px'
	}
]

export default labelsForHypnogram