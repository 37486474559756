import React from 'react'
//import { linearScale } from './functions/LinearScale'
import clamp from './functions/Clamp'
import styles from './css/fillStyle.module.css'

function SWLayer(props) {
	let elements = []
	//console.log(props.events.length)
	for (let i = 0; i < props.events.length; i++) {
		let event = props.events[i]
		let centerline = props.getChannelCenterline(event.channelId)
		let from = clamp(0, event.secondFrom, props.interval)
		let to = clamp(0, event.secondTo, props.interval)
		let xFrom = props.secondToXPosition(from)
		let xTo = props.secondToXPosition(to)
		let xLen = xTo - xFrom
		let top = centerline - props.eventHeight / 2
		//console.log(event.channelId)
		elements.push(
			<rect
				key={event.eventId + '_' + event.channelId}
				x={xFrom}
				y={top}
				width={xLen}
				height={props.eventHeight}
				style={{
					fill: event.color,
					fillOpacity: 0.5
				}}
			/>
		)
		// let bottom = centerline + props.eventHeight / 2
		// elements.push(
		// 	<text
		// 		key={event.eventId + '_' + event.channelId + "_text"}
		// 		x={xFrom}
		// 		y={bottom}
		// 	>
		// 		{Number((event.secondTo - event.secondFrom).toFixed(2))}
		// 	</text>
		// )
	}
	return (
		<svg
			width={props.width}
			height={props.height}
			className={styles.fill}>
			{elements}
		</svg>
	)
}

export default SWLayer