import React, { Component } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import axios from 'axios'

class DrawGraph extends Component {
	constructor(props) {
		super(props)
		this.state = {

		}
	}
    
	componentDidMount () {
		// hypnogram繪製
        let modeArr=this.props.modeArr
        let stage = this.props.stage;
        let msevent=this.props.msevent
		let hHeight = 100;
        let hWidth = stage.length+100;
        //draw master hyp
        let mhCanvas = document.getElementById("master_hypnogram");
        mhCanvas.height = hHeight;
        mhCanvas.width = hWidth;
        let mhCTX = mhCanvas.getContext("2d");
        mhCTX.clearRect(0, 0, mhCanvas.width, mhCanvas.height);
        // 左側Label
        mhCTX.font = "14px Arial";
        mhCTX.textAlign = "end";
        mhCTX.fillStyle = "#AA0000";
        mhCTX.fillText("R", 85, 15);
        mhCTX.fillStyle = "black";
        mhCTX.fillText("W", 85, 30);
        mhCTX.fillStyle = "#CCAA00";
        mhCTX.fillText("N1", 85, 45);
        mhCTX.fillStyle = "green";
        mhCTX.fillText("N2", 85, 60);
        mhCTX.fillStyle = "blue";
        mhCTX.fillText("N3", 85, 75);
        // 畫橫時間軸
        mhCTX.beginPath();
        mhCTX.moveTo(100, 25);
        mhCTX.lineTo(hWidth, 25);
        mhCTX.strokeStyle = '#e8e6e6';
        mhCTX.lineWidth = "1";
        mhCTX.stroke();
        mhCTX.beginPath();
        mhCTX.moveTo(100, 10);
        mhCTX.strokeStyle = 'black';
        mhCTX.lineWidth = "1";
        mhCTX.lineTo(100, 70);
        mhCTX.lineTo(hWidth, 70);
        mhCTX.stroke();
        mhCTX.beginPath();
        mhCTX.moveTo(100, 10);
        mhCTX.strokeStyle = '#e8e6e6';
        mhCTX.lineWidth = "1";
        mhCTX.lineTo(hWidth, 10);
        mhCTX.stroke();
        mhCTX.beginPath();
        mhCTX.moveTo(100, 40);
        mhCTX.strokeStyle = '#e8e6e6';
        mhCTX.lineWidth = "1";
        mhCTX.lineTo(hWidth, 40);
        mhCTX.stroke();
        mhCTX.beginPath();
        mhCTX.moveTo(100, 55);
        mhCTX.strokeStyle = '#e8e6e6';
        mhCTX.lineWidth = "1";
        mhCTX.lineTo(hWidth, 55);
        mhCTX.stroke();
        mhCTX.beginPath();
        for (let i=1;i<this.props.stage.length+1;i++){
            mhCTX.moveTo(i*100+100, 65);
            mhCTX.strokeStyle = 'black';
            mhCTX.lineWidth = "1";
            mhCTX.lineTo(i*100+100, 70);
            mhCTX.stroke();
            mhCTX.fillStyle = "black";
            mhCTX.fillText(String(i*100), i*100+110, 85);
            
        }
        mhCTX.beginPath();

		//console.log(stage);
        for(let i=0; i<stage.length; i++){
            
            if(modeArr[i] === -1){
                mhCTX.fillStyle = "#A2142F";
                mhCTX.fillRect(100+i, 10, 1, 15);
                mhCTX.stroke();
            }
            else{
                if(modeArr[i] === 3){
                    mhCTX.fillStyle = "#0072BD";
                    mhCTX.fillRect(100+i, 25, 1, 45);
                    mhCTX.stroke();
                }
                if(modeArr[i] === 2){
                    mhCTX.fillStyle = "#77AC30";
                    mhCTX.fillRect(100+i, 25, 1, 30);
                    mhCTX.stroke();
                }
                if(modeArr[i] === 1){
                    mhCTX.fillStyle = "#EDB120";
                    mhCTX.fillRect(100+i, 25, 1, 15);
                    mhCTX.stroke();
                }
            } 
        }

		let hCanvas = document.getElementById("hypnogram");
        hCanvas.height = hHeight;
        hCanvas.width = hWidth;
        let hCTX = hCanvas.getContext("2d");
        hCTX.clearRect(0, 0, hCanvas.width, hCanvas.height);
        // 左側Label
        hCTX.font = "14px Arial";
        hCTX.textAlign = "end";
        hCTX.fillStyle = "#AA0000";
        hCTX.fillText("R", 85, 15);
        hCTX.fillStyle = "black";
        hCTX.fillText("W", 85, 30);
        hCTX.fillStyle = "#CCAA00";
        hCTX.fillText("N1", 85, 45);
        hCTX.fillStyle = "green";
        hCTX.fillText("N2", 85, 60);
        hCTX.fillStyle = "blue";
        hCTX.fillText("N3", 85, 75);
        // 畫橫時間軸
        hCTX.beginPath();
        hCTX.moveTo(100, 25);
        hCTX.lineTo(hWidth, 25);
        hCTX.strokeStyle = '#e8e6e6';
        hCTX.lineWidth = "1";
        hCTX.stroke();
        hCTX.beginPath();
        hCTX.moveTo(100, 10);
        hCTX.strokeStyle = 'black';
        hCTX.lineWidth = "1";
        hCTX.lineTo(100, 70);
        hCTX.lineTo(hWidth, 70);
        hCTX.stroke();
        hCTX.beginPath();
        hCTX.moveTo(100, 10);
        hCTX.strokeStyle = '#e8e6e6';
        hCTX.lineWidth = "1";
        hCTX.lineTo(hWidth, 10);
        hCTX.stroke();
        hCTX.beginPath();
        hCTX.moveTo(100, 40);
        hCTX.strokeStyle = '#e8e6e6';
        hCTX.lineWidth = "1";
        hCTX.lineTo(hWidth, 40);
        hCTX.stroke();
        hCTX.beginPath();
        hCTX.moveTo(100, 55);
        hCTX.strokeStyle = '#e8e6e6';
        hCTX.lineWidth = "1";
        hCTX.lineTo(hWidth, 55);
        hCTX.stroke();
        hCTX.beginPath();
        for (let i=1;i<this.props.stage.length+1;i++){
            hCTX.moveTo(i*100+100, 65);
            hCTX.strokeStyle = 'black';
            hCTX.lineWidth = "1";
            hCTX.lineTo(i*100+100, 70);
            hCTX.stroke();
            hCTX.fillStyle = "black";
            hCTX.fillText(String(i*100), i*100+110, 85);
            
        }
        hCTX.beginPath();

		//console.log(stage);
        for(let i=0; i<stage.length; i++){
            
            if(stage[i] === -1){
                hCTX.fillStyle = "#A2142F";
                hCTX.fillRect(100+i, 10, 1, 15);
                hCTX.stroke();
            }
            else{
                if(stage[i] === 3){
                    hCTX.fillStyle = "#0072BD";
                    hCTX.fillRect(100+i, 25, 1, 45);
                    hCTX.stroke();
                }
                if(stage[i] === 2){
                    hCTX.fillStyle = "#77AC30";
                    hCTX.fillRect(100+i, 25, 1, 30);
                    hCTX.stroke();
                }
                if(stage[i] === 1){
                    hCTX.fillStyle = "#EDB120";
                    hCTX.fillRect(100+i, 25, 1, 15);
                    hCTX.stroke();
                }
            } 
        }


        //draw Respiratory_Event
        let rCanvas = document.getElementById("Respiratory_Event");
        rCanvas.height = hHeight;
        rCanvas.width = hWidth;
        let rCTX = rCanvas.getContext("2d");
        rCTX.clearRect(0, 0, rCanvas.width, rCanvas.height);
        rCTX.font = "14px Arial";
        rCTX.textAlign = "end";
        rCTX.fillStyle = "black";
        rCTX.fillText("MxA", 85, 15);
        rCTX.fillStyle = "black";
        rCTX.fillText("CnA", 85, 30);
        rCTX.fillStyle = "black";
        rCTX.fillText("ObA", 85, 45);
        rCTX.fillStyle = "black";
        rCTX.fillText("Hyp", 85, 75);
        //draw arousal line
        rCTX.beginPath();
        rCTX.moveTo(100, 10);
        rCTX.strokeStyle = 'black';
        rCTX.lineWidth = "1";
        rCTX.lineTo(100, 70);
        rCTX.lineTo(hWidth, 70);
        rCTX.stroke();
        rCTX.beginPath();
        rCTX.moveTo(100, 10);
        rCTX.strokeStyle = '#e8e6e6';
        rCTX.lineWidth = "1";
        rCTX.lineTo(hWidth, 10);
        rCTX.stroke();
        rCTX.beginPath();
        rCTX.moveTo(100, 40);
        rCTX.strokeStyle = '#e8e6e6';
        rCTX.lineWidth = "1";
        rCTX.lineTo(hWidth, 40);
        rCTX.stroke();
        rCTX.beginPath();
        rCTX.beginPath();
        rCTX.moveTo(100, 25);
        rCTX.strokeStyle = '#e8e6e6';
        rCTX.lineWidth = "1";
        rCTX.lineTo(hWidth, 25);
        rCTX.stroke();
        rCTX.beginPath();
        rCTX.beginPath();
        rCTX.moveTo(100, 55);
        rCTX.strokeStyle = '#e8e6e6';
        rCTX.lineWidth = "1";
        rCTX.lineTo(hWidth, 55);
        rCTX.stroke();
        rCTX.beginPath();
        for (let i=1;i<this.props.stage.length+1;i++){
            rCTX.moveTo(i*100+100, 65);
            rCTX.strokeStyle = 'black';
            rCTX.lineWidth = "1";
            rCTX.lineTo(i*100+100, 70);
            rCTX.stroke();
            rCTX.fillStyle = "black";
            rCTX.fillText(String(i*100), i*100+110, 85);
        }
        rCTX.beginPath();
        let Mixed_Apnea_s=this.props.Mixed_Apnea_s
		let Obstructive_Apnea_s=this.props.Obstructive_Apnea_s
		let Central_Apnea_s=this.props.Central_Apnea_s
		let Hypopnea_s=this.props.Hypopnea_s
        for(let i=0; i<stage.length; i++){
            if(Hypopnea_s[i]===1){
                rCTX.fillStyle = "#49ba01";
                rCTX.fillRect(100+i, 55, 1, 15);
                rCTX.stroke();
            }
            if(Obstructive_Apnea_s[i]===1){
                rCTX.fillStyle = "#e20147";
                rCTX.fillRect(100+i, 40, 1, 15);
                rCTX.stroke();
            }
            if(Central_Apnea_s[i]===1){
                rCTX.fillStyle = "#ed507f";
                rCTX.fillRect(100+i, 25, 1, 30);
                rCTX.stroke();
            }
            if(Mixed_Apnea_s[i]===1){
                rCTX.fillStyle = "#000000";
                rCTX.fillRect(100+i, 10, 1, 45);
                rCTX.stroke();
            }
        }

        //draw arousal
        let aCanvas = document.getElementById("arousal");
        aCanvas.height = hHeight;
        aCanvas.width = hWidth;
        let aCTX = aCanvas.getContext("2d");
        aCTX.clearRect(0, 0, aCanvas.width, aCanvas.height);
        aCTX.font = "14px Arial";
        aCTX.textAlign = "end";
        aCTX.fillStyle = "black";
        aCTX.fillText("yes", 85, 15);
        aCTX.fillStyle = "black";
        aCTX.fillText("no", 85, 75);
        //draw line
        aCTX.beginPath();
        aCTX.moveTo(100, 10);
        aCTX.strokeStyle = 'black';
        aCTX.lineWidth = "1";
        aCTX.lineTo(100, 70);
        aCTX.lineTo(hWidth, 70);
        aCTX.stroke();
        aCTX.beginPath();
        aCTX.moveTo(100, 10);
        aCTX.strokeStyle = '#e8e6e6';
        aCTX.lineWidth = "1";
        aCTX.lineTo(hWidth, 10);
        aCTX.stroke();
        aCTX.beginPath();
        for (let i=1;i<this.props.stage.length+1;i++){
            aCTX.moveTo(i*100+100, 65);
            aCTX.strokeStyle = 'black';
            aCTX.lineWidth = "1";
            aCTX.lineTo(i*100+100, 70);
            aCTX.stroke();
            aCTX.fillStyle = "black";
            aCTX.fillText(String(i*100), i*100+110, 85);
        }
        let arousal_s=this.props.arousal_s
        for(let i=0; i<stage.length; i++) {
            if(arousal_s[i]===1){
                aCTX.fillStyle = "#2190c5";
                aCTX.fillRect(100+i, 10, 1, 60);
                aCTX.stroke();
            }
        }

        //draw spo2
        let sCanvas = document.getElementById("spo2");
        sCanvas.height = hHeight;
        sCanvas.width = hWidth;
        let sCTX = sCanvas.getContext("2d");
        sCTX.clearRect(0, 0, sCanvas.width, sCanvas.height);
        sCTX.font = "14px Arial";
        sCTX.textAlign = "end";
        sCTX.fillStyle = "black";
        sCTX.fillText("yes", 85, 15);
        sCTX.fillStyle = "black";
        sCTX.fillText("no", 85, 75);
        //draw line
        sCTX.beginPath();
        sCTX.moveTo(100, 10);
        sCTX.strokeStyle = 'black';
        sCTX.lineWidth = "1";
        sCTX.lineTo(100, 70);
        sCTX.lineTo(hWidth, 70);
        sCTX.stroke();
        sCTX.beginPath();
        sCTX.moveTo(100, 10);
        sCTX.strokeStyle = '#e8e6e6';
        sCTX.lineWidth = "1";
        sCTX.lineTo(hWidth, 10);
        sCTX.stroke();
        sCTX.beginPath();
        for (let i=1;i<this.props.stage.length+1;i++){
            sCTX.moveTo(i*100+100, 65);
            sCTX.strokeStyle = 'black';
            sCTX.lineWidth = "1";
            sCTX.lineTo(i*100+100, 70);
            sCTX.stroke();
            sCTX.fillStyle = "black";
            sCTX.fillText(String(i*100), i*100+110, 85);
        }
        let spo2_s = this.props.spo2_s
        for (let i=0; i<stage.length; i++) {
            if(spo2_s[i]===1){
                sCTX.fillStyle = "#2190c5";
                sCTX.fillRect(100+i, 10, 1, 60);
                sCTX.stroke();
            }
        }

        //draw limb
        let lCanvas = document.getElementById("limb");
        lCanvas.height = hHeight;
        lCanvas.width = hWidth;
        let lCTX = lCanvas.getContext("2d");
        lCTX.clearRect(0, 0, lCanvas.width, lCanvas.height);
        lCTX.font = "14px Arial";
        lCTX.textAlign = "end";
        lCTX.fillStyle = "black";
        lCTX.fillText("yes", 85, 15);
        lCTX.fillStyle = "black";
        lCTX.fillText("no", 85, 75);
        //draw line
        lCTX.beginPath();
        lCTX.moveTo(100, 10);
        lCTX.strokeStyle = 'black';
        lCTX.lineWidth = "1";
        lCTX.lineTo(100, 70);
        lCTX.lineTo(hWidth, 70);
        lCTX.stroke();
        lCTX.beginPath();
        lCTX.moveTo(100, 10);
        lCTX.strokeStyle = '#e8e6e6';
        lCTX.lineWidth = "1";
        lCTX.lineTo(hWidth, 10);
        lCTX.stroke();
        lCTX.beginPath();
        for (let i=1;i<this.props.stage.length+1;i++){
            lCTX.moveTo(i*100+100, 65);
            lCTX.strokeStyle = 'black';
            lCTX.lineWidth = "1";
            lCTX.lineTo(i*100+100, 70);
            lCTX.stroke();
            lCTX.fillStyle = "black";
            lCTX.fillText(String(i*100), i*100+110, 85);
        }
        let limb_r_s=this.props.limb_r_s
        for (let i=0; i<stage.length; i++) {
            if(limb_r_s[i]===1){
                lCTX.fillStyle = "#A2142F";
                lCTX.fillRect(100+i, 10, 1, 60);
                lCTX.stroke();
            }
        }
		//繪製完成後存起來
		
		let graphData = {
			hypnogram: document.getElementById("hypnogram").toDataURL('image/png'),
            Respiratory_Event: document.getElementById("Respiratory_Event").toDataURL('image/png'),
            arousal: document.getElementById("arousal").toDataURL('image/png'),
            spo2: document.getElementById("spo2").toDataURL('image/png'),
            limb: document.getElementById("limb").toDataURL('image/png'),
            master_hypnogram: document.getElementById("master_hypnogram").toDataURL('image/png'),
		}
		this.props.saveGraph(graphData);
        if(this.props.wait01===false){
            this.props.oknext01(true)
        }
	}

	render () {
		return(
			<>
				<div style={{display:this.props.visible, position: "absolute", width:"100%", fontSize:"20px", backgroundColor:"white", zIndex:"100"}}>
					<canvas id = "hypnogram" width = "1000px" height = "100px" style={{margin:"5px"}}/><br />
					<canvas id = "Respiratory_Event" width = "1000px" height = "100px" style={{margin:"5px"}}/><br />
                    <canvas id = "arousal" width = "1000px" height = "100px" style={{margin:"5px"}}/><br />
                    <canvas id = "spo2" width = "1000px" height = "100px" style={{margin:"5px"}}/><br />
                    <canvas id = "limb" width = "1000px" height = "100px" style={{margin:"5px"}}/><br />
                    <canvas id = "master_hypnogram" width = "1000px" height = "100px" style={{margin:"5px"}}/><br />
				</div>
			</>
		)
	}
}

export default DrawGraph