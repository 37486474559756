const units = Object.freeze({
	1: 'µV',
	2: 'µV',
	3: 'µV',
	4: 'µV',
	5: 'µV',
	6: 'µV',
	7: 'µV',
	8: 'µV',
	9: 'µV',
	10: 'mV',
	11: 'mV',
	12: 'cmH2O',
	13: 'µV',
	14: 'µV',
	15: 'µV',
	16: '%',
	17: 'µV',
	18: 'µV',
	19: 'bpm',
	20: ''
})

export default units