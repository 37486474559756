import React, { Component } from 'react'


class EventDataCon extends Component {
    constructor(props) {
		super(props)
		this.state = {

		}
	}
    //拿技師事件

    SumDatareduce=(arr)=>{
        return arr.reduce((a,b)=>a+b);
    }

    componentDidMount () {
        let msevent=this.props.msevent
        let my_stage=this.props.stage
        let my_modeArr=this.props.modeArr
        let every_thing=[]
        //計算一致性
        let hyp=Array(5).fill(0).map(() => new Array(5).fill(0))
        for (let i=0;i<my_stage.length;i++){
            if(my_modeArr[i]===-1){
                if(my_stage[i]===-1){
                    hyp[my_modeArr[i]+5][my_stage[i]+5]++;
                }else{
                    hyp[my_modeArr[i]+5][my_stage[i]]++;
                }
            }else{
                if(my_stage[i]===-1){
                    hyp[my_modeArr[i]][my_stage[i]+5]++;
                }else{
                    hyp[my_modeArr[i]][my_stage[i]]++;
                }
            }
            
        }
        for(let i=0;i<5;i++){
            let sum=this.SumDatareduce(hyp[i])
            for(let j=0;j<5;j++){
                if(sum === 0 && i !== j) {
                    hyp[i][j] = 0
                } else if (sum === 0 && i === j) {
                    hyp[i][j] = 100
                } else {
                    hyp[i][j]=(hyp[i][j]/sum*100).toFixed(1)
                }
            }
        }
        let re=Array(5).fill(0).map(() => new Array(5).fill(0))
        let Mixed_Apnea_s=this.props.Mixed_Apnea_s
        let Obstructive_Apnea_s=this.props.Obstructive_Apnea_s
        let Central_Apnea_s=this.props.Central_Apnea_s
        let Hypopnea_s=this.props.Hypopnea_s
        let all_re=Array(),all_re_m=Array()
        /* ********* ******
            z_c不知道是啥的縮寫, 但總之看起來是算報告內呼吸事件None的個數
        ********* ****** */
        let z_c=Array(my_stage.length).fill(0)
        for (let i=0;i<my_stage.length;i++){
            if(Hypopnea_s[i]===0 && Obstructive_Apnea_s[i]===0 && Central_Apnea_s[i]===0 && Mixed_Apnea_s[i]===0){
                z_c[i]=1
            }
        }
        let z_c_m=Array(my_stage.length).fill(0)
        for (let i=0;i<my_stage.length;i++){
            if(msevent[0][i]===0 && msevent[1][i]===0 && msevent[2][i]===0 && msevent[3][i]===0){
                z_c_m[i]=1
            }
        }

        all_re.push(z_c)
        all_re.push(Hypopnea_s)
        all_re.push(Obstructive_Apnea_s)
        all_re.push(Central_Apnea_s)
        all_re.push(Mixed_Apnea_s)
        all_re_m.push(z_c_m)
        all_re_m.push(msevent[0])
        all_re_m.push(msevent[1])
        all_re_m.push(msevent[2])
        all_re_m.push(msevent[3])
        for (let i=0;i<my_stage.length;i++){
            for(let j=0;j<5;j++){
                for(let h=0;h<5;h++){
                    if(all_re[j][i]===all_re_m[h][i] && all_re_m[h][i]===1){
                        re[h][j]++
                    }
                }
            }
        }
        // 感覺有 bug (all_re_m 有問題)
        // console.log("all_re: ")
        // console.log(all_re)
        // console.log("all_re_m: ")
        // console.log(all_re_m)

        for(let i=0;i<5;i++){
            let sum=this.SumDatareduce(re[i])
            for(let j=0;j<5;j++){
                if(sum === 0 && i !== j) {
                    re[i][j] = 0
                } else if (sum === 0 && i === j) {
                    re[i][j] = 100
                } else {
                    re[i][j]=(re[i][j]/sum*100).toFixed(1);
                }
            }
        }
        

        let ar=Array(2).fill(0).map(() => new Array(2).fill(0))
        let arousal_s=this.props.arousal_s
        for (let i=0;i<my_stage.length;i++){
            ar[msevent[4][i]][arousal_s[i]]++;
        }
        for(let i=0;i<2;i++){
            let sum=this.SumDatareduce(ar[i])
            for(let j=0;j<2;j++){
                if(sum === 0 && i !== j) {
                    ar[i][j] = 0
                } else if (sum === 0 && i === j) {
                    ar[i][j] = 100
                } else {
                    ar[i][j]=(ar[i][j]/sum*100).toFixed(1);
                }
            }
        }

        let sp=Array(2).fill(0).map(() => new Array(2).fill(0))
        let spo2_s = this.props.spo2_s
        for (let i=0;i<my_stage.length;i++){
            sp[msevent[5][i]][spo2_s[i]]++;
        }
        for(let i=0;i<2;i++){
            let sum=this.SumDatareduce(sp[i])
            for(let j=0;j<2;j++){
                if(sum === 0 && i !== j) {
                    sp[i][j] = 0
                } else if (sum === 0 && i === j) {
                    sp[i][j] = 100
                } else {
                    sp[i][j]=(sp[i][j]/sum*100).toFixed(1);
                }
            }
        }
        
        let lm=Array(2).fill(0).map(() => new Array(3).fill(0))
        let limb_r_s = this.props.limb_r_s
        let all_limb=Array(),all_limb_m=Array()
        let z_c_limb=Array(my_stage.length).fill(0)
        for (let i=0;i<my_stage.length;i++){
            if(limb_r_s[i]===0 ){
                z_c_limb[i]=1
            }
        }
        let z_c_limb_m=Array(my_stage.length).fill(0)
        for (let i=0;i<my_stage.length;i++){
            if(msevent[6][i]===0 ){
                z_c_limb_m[i]=1
            }
        }
        all_limb.push(z_c_limb)
        all_limb.push(limb_r_s)
        all_limb_m.push(z_c_limb_m)
        all_limb_m.push(msevent[6])
        for (let i=0;i<my_stage.length;i++){
            for(let j=0;j<2;j++){
                for(let h=0;h<2;h++){
                    if(all_limb[j][i]===all_limb_m[h][i] && all_limb_m[h][i]===1){
                        lm[h][j]++
                    }
                }
            }
        }
        for(let i=0;i<2;i++){
            let sum=this.SumDatareduce(lm[i])
            if (sum!=0){
                for(let j=0;j<2;j++){
                    lm[i][j]=(lm[i][j]/sum*100).toFixed(1);
                }
            }
        }
        //計算報表
        let reportlist=Array(40).fill(0);
        let sot = 0;
        let wake = 0;
        let n1 = 0;
        let n2 = 0;
        let n3 = 0;
        let rem = 0;
        for(let i=0; i<my_stage.length; i++){
            if(my_stage[i] === 0) wake++;
            else if(my_stage[i] === 1) n1++;
            else if(my_stage[i] === 2) n2++;
            else if(my_stage[i] === 3) n3++;
            else if(my_stage[i] === -1) rem++;

            if((i+1) === wake) sot++;
        }

        let OA=0;
        let CA=0;
        let MA=0
        let OH=0;
        let AHI_rem=0
        let ARO=0
        let MAX_A=0,MAX_OH=0
        let total_OA=0,total_CA=0,total_MA=0,total_OH=0
        let PLMFL=[],PLMTL=[],PLM_REM=0
        for (const [_, value] of Object.entries(this.props.all_event)) {
            if(value.type==="Mixed Apnea"){
                MA++;
                if(value.secondTo-value.secondFrom>MAX_A){
                    MAX_A=value.secondTo-value.secondFrom
                }
                total_MA=total_MA+value.secondTo-value.secondFrom
                if(my_stage[Math.floor(value.secondTo/30)]===-1){ 
                    AHI_rem++;
                }
            }
            else if(value.type==="Obstructive Apnea"){
                OA++;
                if(value.secondTo-value.secondFrom>MAX_A){
                    MAX_A=value.secondTo-value.secondFrom
                }
                total_OA=total_OA+value.secondTo-value.secondFrom
                if(my_stage[Math.floor(value.secondTo/30)]===-1){ 
                    AHI_rem++;
                }
            }
            else if(value.type==="Central Apnea"){
                CA++;
                if(value.secondTo-value.secondFrom>MAX_A){
                    MAX_A=value.secondTo-value.secondFrom
                }
                total_CA=total_CA+value.secondTo-value.secondFrom
                if(my_stage[Math.floor(value.secondTo/30)]===-1){ 
                    AHI_rem++;
                }
            }
            else if(value.type==="Obstructive Hypopnea"||value.type==="Central Hypopnea"||value.type==="Mixed Hypopnea"){
                OH++;
                if(value.secondTo-value.secondFrom>MAX_OH){
                    MAX_OH=value.secondTo-value.secondFrom
                }
                total_OH=total_OH+value.secondTo-value.secondFrom
                if(my_stage[Math.floor(value.secondTo/30)]===-1){ 
                    AHI_rem++;
                }
            }
            else if(value.type==="ARO SPONT"){
                ARO++
            }
            else if(value.type==="Limb movement (Right)"){
                PLMFL.push(value.secondFrom)
                PLMTL.push(value.secondTo)
                if(my_stage[Math.floor(value.secondTo/30)]===-1){ 
                    PLM_REM++;
                }
            }
            else if(value.type==="Limb movement (Left)"){
                PLMFL.push(value.secondFrom)
                PLMTL.push(value.secondTo)
                if(my_stage[Math.floor(value.secondTo/30)]===-1){ 
                    PLM_REM++;
                }
            }
        }
        let PLMS=[],tmp_PLMS=[],tr=0
        for (let i=0;i<PLMTL.length-1;i++){
            if((PLMFL[i+1]-PLMTL[i]<=90)&&tr===0){
                tmp_PLMS.push(PLMTL[i]);
                tmp_PLMS.push(PLMTL[i+1])
                //console.log(tmp_PLMS.length)
                tr=1
            }else if((PLMFL[i+1]-PLMTL[i]<=90)&&tr===1){
                tmp_PLMS.push(PLMTL[i+1])
                //console.log(tmp_PLMS.length)
            }else if((PLMFL[i+1]-PLMTL[i]>90)){
                tmp_PLMS=[]
                tr=0
            }
            if(tmp_PLMS.length===4){
                for (let j=0;j<4;j++){
                    PLMS.push(tmp_PLMS[j])
                }
            }else if(tmp_PLMS.length>4){
                PLMS.push(tmp_PLMS[tmp_PLMS.length-1])
            }

        }
        let PLMS_REM=0
        for(let i=0;i<PLMS.length;i++){
            if(my_stage[Math.floor(PLMS[i])]===-1){
                PLMS_REM++;
            }
        }
        reportlist[0]=((OA+CA+MA+OH)/((my_stage.length-wake)/120)).toFixed(1); // AHI
        reportlist[1]=((OA+CA+MA)/((my_stage.length-wake)/120)).toFixed(1); // AI
        reportlist[2]=((OH)/((my_stage.length-wake)/120)).toFixed(1); // HI
        reportlist[3]=((OA)/((my_stage.length-wake)/120)).toFixed(1); // OI
        reportlist[4]=((CA)/((my_stage.length-wake)/120)).toFixed(1); // CI
        reportlist[5]=((MA)/((my_stage.length-wake)/120)).toFixed(1); // MI
        reportlist[6]=((AHI_rem)/((my_stage.length-wake)/120)).toFixed(1); // AHI(REM)
        reportlist[7]=(reportlist[0]-reportlist[6]).toFixed(1); // AHI(NREM)
        reportlist[8]=(my_stage.length/2).toFixed(1); // total record time
        reportlist[9]=((my_stage.length-sot)/2).toFixed(1); // total sleep period
        reportlist[10]=((my_stage.length-wake)/2).toFixed(1); // total sleep time
        reportlist[11]=(wake/2).toFixed(1); // awake time
        reportlist[12]=(n1/(my_stage.length-wake)*100).toFixed(1); // N1 %
        reportlist[13]=(rem/(my_stage.length-wake)*100).toFixed(1); // REM %
        reportlist[14]=(n2/(my_stage.length-wake)*100).toFixed(1); // N2 %
        reportlist[15]=(sot/2).toFixed(1); // sleep latency
        reportlist[16]=(n3/(my_stage.length-wake)*100).toFixed(1); // N3 %
        reportlist[17]=((my_stage.length-wake)/my_stage.length*100).toFixed(1); // efficiency
        reportlist[18]=((ARO)/((my_stage.length-wake)/120)).toFixed(1); // arousal index
        reportlist[19]=OA; // OA count
        reportlist[20]=(total_OA/60).toFixed(1); // OA duration
        reportlist[21]=CA; // CA count
        reportlist[22]=(total_CA/60).toFixed(1); // CA duration
        reportlist[23]=MA; // MA count
        reportlist[24]=(total_MA/60).toFixed(1); // MA duration
        reportlist[25]=OH; // H count
        reportlist[26]=(total_OH/60).toFixed(1); // H duration
        reportlist[27]=(MAX_A).toFixed(1); // longest apnea
        reportlist[28]=(MAX_OH).toFixed(1); // longhest hypopnea
        // if(this.props.spo2_deast.length!=0){
            // reportlist[29]=-(this.SumDatareduce(this.props.spo2_deast)/this.props.spo2_deast.length).toFixed(1);
        // }
        if(this.props.spo2_respiratory.length!=0){ // Mean Spo2 with respiratory events
            reportlist[29]=(this.SumDatareduce(this.props.spo2_respiratory)/this.props.spo2_respiratory.length).toFixed(1);
        }
        reportlist[30]=(this.props.spo2_deast.length/((my_stage.length-wake)/120)).toFixed(1); // ODI
        reportlist[31]=PLM_REM;
        reportlist[32]=(PLMFL.length-PLM_REM);
        reportlist[33]=(PLMFL.length);
        reportlist[34]=PLMS_REM;
        reportlist[35]=(PLMS.length-PLMS_REM);
        reportlist[36]=(PLMS.length);
        reportlist[37]=(PLMS_REM/((my_stage.length-wake)/120)).toFixed(1);
        reportlist[38]=((PLMS.length-PLMS_REM)/((my_stage.length-wake)/120)).toFixed(1);
        reportlist[39]=((PLMS.length)/((my_stage.length-wake)/120)).toFixed(1);


        let fa_reportlist=Array(40).fill(0);
        let fa_sot = 0;
        let fa_wake = 0;
        let fa_n1 = 0;
        let fa_n2 = 0;
        let fa_n3 = 0;
        let fa_rem = 0;
        for(let i=0; i<my_stage.length; i++){
            if(my_modeArr[i] === 0) fa_wake++;
            else if(my_modeArr[i] === 1) fa_n1++;
            else if(my_modeArr[i] === 2) fa_n2++;
            else if(my_modeArr[i] === 3) fa_n3++;
            else if(my_modeArr[i] === -1) fa_rem++;

            if((i+1) === fa_wake) fa_sot++;
        }
        fa_reportlist[9]=((my_stage.length-fa_sot)/2).toFixed(1);
        fa_reportlist[10]=((my_stage.length-fa_wake)/2).toFixed(1);
        fa_reportlist[11]=(fa_wake/2).toFixed(1);
        fa_reportlist[12]=(fa_n1/my_stage.length*100).toFixed(1);
        fa_reportlist[13]=(fa_rem/my_stage.length*100).toFixed(1);
        fa_reportlist[14]=(fa_n2/my_stage.length*100).toFixed(1);
        fa_reportlist[15]=(fa_sot/2).toFixed(1);
        fa_reportlist[16]=(fa_n3/my_stage.length*100).toFixed(1);
        fa_reportlist[17]=((my_stage.length-fa_wake)/my_stage.length*100).toFixed(1);
        /* ********* ******
            下載的時候才計算一致性寫入後端的json檔裡
            所以第一位下載的user就是1/1名
            第二位可能是1/2或2/2
            2022學長指使學姐登入所有使用者號先下載過一遍應該是要讓一致性分數都寫入json檔
        ********* ****** */        
        // let all_add_apnea=[],m_all_add_apnea=[]
        // for (let i=0;i<my_stage.length;i++){
        //     if (Obstructive_Apnea_s[i]===1 || Central_Apnea_s[i]===1 || Mixed_Apnea_s[i]===1){
        //         all_add_apnea.push(1)
        //     }else{
        //         all_add_apnea.push(0)
        //     }
        // }
        // for (let i=0;i<my_stage.length;i++){
        //     if (msevent[1][i]===1 || msevent[2][i]===1 || msevent[3][i]===1){
        //         m_all_add_apnea.push(1)
        //     }else{
        //         m_all_add_apnea.push(0)
        //     }
        // }
        // let score_hyp=0,score_re=0,score_ar=0,score_spo2=0,score_lm=0
        // for (let i=0;i<my_stage.length;i++){
        //     if(my_modeArr[i]===my_stage[i]){
        //         score_hyp++;
        //     }
        //     if(all_add_apnea[i]===m_all_add_apnea[i]){
        //         if(msevent[0][i]===Hypopnea_s[i]){
        //             score_re++;
        //         }
        //     }
        //     if(msevent[4][i]===arousal_s[i]){
        //         score_ar++;
        //     }
        //     if(msevent[5][i]===spo2_s[i]){
        //         score_spo2++;
        //     }
        //     if(msevent[6][i]===limb_r_s[i]){
        //         score_lm++;
        //     }
        // }
        // let test_1=0,test_2=0,test_3=0,test_4=0,test_5=0
        // for (let i=0;i<my_stage.length;i++){
        //     if(all_add_apnea[i]==1){
        //         test_1++;
        //     }
        //     if(Hypopnea_s[i]==1){
        //         test_2++;
        //     }
        //     if(arousal_s[i]==1){
        //         test_3++;
        //     }
        //     if(spo2_s[i]==1){
        //         test_4++;
        //     }
        //     if(limb_r_s[i]==1){
        //         test_5++;
        //     }
        // }
        // if(test_1==0 && test_2==0){
        //     score_re=0;
        // }
        // if(test_3==0){
        //     score_ar=0;
        // }
        // if(test_4==0){
        //     score_spo2=0;
        // }
        // if(test_5==0){
        //     score_lm=0;
        // }
        let all_score=[];
        // all_score.push(((score_hyp/my_stage.length)*100).toFixed(1))
        // all_score.push(((score_re/my_stage.length)*100).toFixed(1))
        // all_score.push(((score_ar/my_stage.length)*100).toFixed(1))
        // all_score.push(((score_spo2/my_stage.length)*100).toFixed(1))
        // all_score.push(((score_lm/my_stage.length)*100).toFixed(1))

 
        every_thing.push(hyp) // 學員與專家 stage 一致性表格
        every_thing.push(re) // 學員與專家 respiration 一致性表格
        every_thing.push(ar) // 學員與專家 arousal 一致性表格
        every_thing.push(sp) // 學員與專家 SpO2 一致性表格
        every_thing.push(lm) // 學員與專家 PLM 一致性表格
        every_thing.push(reportlist)
        every_thing.push(all_score)
        every_thing.push(fa_reportlist)

        if(this.props.eventData.length === 0){
            this.props.savedata(every_thing);
        }
        if(this.props.wait02===false){
            this.props.oknext02(true)
        }
    }
    render(){
        return null;
    }



}
export default EventDataCon