function getChannelEvents(channelId) {
	// original ver.
	// const respiratoryEvents = ['Obstructive Apnea', 'Central Apnea', 'Mixed Apnea', 'Obstructive Hypopnea', 'Central Hypopnea', 'Mixed Hypopnea', 'Unsure Respiratory Event', 'Respiratory Artifact', 'Respiratory Paradox', 'Cheyne Stokes Breathing']
	// 2023 workshop ver.
	const respiratoryEvents = ['Obstructive Apnea', 'Central Apnea', 'Mixed Apnea', 'Obstructive Hypopnea', 'RERA']
	// also remove 16：SpO2 Artifact
	
	let channelIdToArrayOfEvents = {
		1: ['ARO SPONT', 'Slow Wave'],
		2: ['ARO SPONT', 'Slow Wave'],
		3: ['Slow Wave'],
		4: ['Slow Wave'],
		5: ['Slow Wave'],
		6: ['Slow Wave'],
		7: [],
		8: [],
		9: [],
		10: [],
		11: ['Snore'],
		12: respiratoryEvents,
		13: respiratoryEvents,
		14: [],
		15: [],
		16: ['SpO2 Desat'],
		17: ['Limb movement (Left)'],
		18: ['Limb movement (Right)'],
		19: [],
		20: [],
	}
	return channelIdToArrayOfEvents[channelId]
}

export default getChannelEvents