export function totalSWTime(swIntervals) {
	if (!swIntervals || swIntervals.length === 0) {
        return 0
    }

    let merged = []
    swIntervals.sort((a, b) => a[0] - b[0])
    
    let mergedInterval = swIntervals[0]
    for (let i = 1; i < swIntervals.length; i++) {
        let interval = swIntervals[i]

        if (interval[0] <= mergedInterval[1]) {
            mergedInterval[1] = Math.max(mergedInterval[1], interval[1])
        } else {
            merged.push(mergedInterval)
            mergedInterval = interval
        }
    }

    merged.push(mergedInterval)

    let totalTime = 0
    for(let i = 0; i < merged.length; i++) {
        totalTime += (merged[i][1] - merged[i][0])
    }

    return totalTime.toFixed(2)
}